import React from "react"
import PortableText from "@sanity/block-content-to-react"
import {graphql, Link} from "gatsby"
import {Layout} from "../components/layout"
import styled from "styled-components"
import { P, H1, H2, Button} from "../components/Typography"
import * as Sections from "../components/SectionElements"
import {GatsbyImage, getImage} from 'gatsby-plugin-image'
import * as Serializer from "../components/Serializer"
import Seo from "../components/seo"

const Title = styled(H1)`
  padding-bottom: 0;
`
const Subtitle = styled(H2)`
  font-weight: 400;
  color: ${props => props.color ? props.color : props.theme.colors.secondary};
`
const ColoredSpan = styled.span`
  font-weight: 400;
  background-color: ${props => props.color ? props.color : props.theme.colors.flash};
  padding: 2px 5px;
  border-radius: 5px;
  box-decoration-break: clone;
  line-height: 1.7;
  color: ${props => props.color ? props.color : props.theme.colors.secondary};
`

const MaquetteGrid = styled.div`
  position: relative;
  bottom: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // justify-content: space-evenly;
  align-content: flex-start;
  width: 100%;
  list-style: none;
  margin: auto;
  padding: 0;
  row-gap: ${props => props.spacing ? props.spacing : props.theme.spacings.small};
  column-gap: ${props => props.spacing ? props.spacing : props.theme.spacings.small};
`

const FlexItem = styled.div`
  min-width: 10vw;
  max-width: 40vw;

  @media ${props => props.theme.breakpoints.desktop} {
    min-width: 10vw;
    max-width: 40vw;
  }
`
const ModuleButton = styled.button`
font-size: 18px; 
line-height: 25px; 
font-weight: 400;
cursor: pointer;
text-align: left;

> a {
    padding: 2px 5px;
    border-radius: 5px;
    box-decoration-break: clone;
    background-color: ${props => props.color ? props.color : props.theme.colors.grey1};
    color: ${props => props.color ? props.color : props.theme.colors.foreground};

    :hover  {
        background-color: ${props => props.color ? props.color : props.theme.colors.flash};
    }
}

@media ${props => props.theme.breakpoints.desktop} {
  font-size: 24px; 
  line-height: 34px; 
  font-weight: 400;
}
`

const NameUrl = styled(Button)`
  margin-right: ${props => props.spacing ? props.spacing : props.theme.spacings.xxxSmall};
`

const Schoolyear = styled(P)`
  > span {
    padding: 4px 8px;
    background-color: ${props => props.color ? props.color : props.theme.colors.secondary};
    color: ${props => props.color ? props.color : props.theme.colors.background};
    border-radius: ${props => props.spacing ? props.spacing : props.theme.spacings.small};
    // border: 1px solid ${props => props.theme.colors.secondary};
  }
  margin: auto;
  margin-top: ${props => props.spacing ? props.spacing : props.theme.spacings.xSmall};
  margin-bottom: ${props => props.spacing ? props.spacing : props.theme.spacings.small};
  text-align: center;
  @media ${props => props.theme.breakpoints.desktop} {
    margin-top: ${props => props.spacing ? props.spacing : props.theme.spacings.small};
    margin-bottom: ${props => props.spacing ? props.spacing : props.theme.spacings.small};
  }
`

const VisitorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  row-gap: ${props => props.spacing ? props.spacing : props.theme.spacings.xxSmall};
  padding-bottom: ${props => props.spacing ? props.spacing : props.theme.spacings.small};
`
const NameContainer = styled(P)`
  display: inline;
  > span {
    background-color: ${props => props.color ? props.color : props.theme.colors.flash};
    padding: 2px 5px;
    border-radius: 5px;
    box-decoration-break: clone;
    color: ${props => props.color ? props.color : props.theme.colors.foreground};
    margin-right: ${props => props.spacing ? props.spacing : props.theme.spacings.xxxSmall};
  }
`
const CompanyContainer = styled(P)`
  display: inline;
  > span {
    background-color: ${props => props.color ? props.color : props.theme.colors.secondary};
    padding: 2px 5px;
    border-radius: ${props => props.spacing ? props.spacing : props.theme.spacings.small};
    box-decoration-break: clone;
    color: ${props => props.color ? props.color : props.theme.colors.background};
    margin-right: ${props => props.spacing ? props.spacing : props.theme.spacings.xxxSmall};
  }
`
const RoleContainer = styled(P)`
  display: inline;
  // margin-top:-.15em;
  > span {
    // line-height: 1.7;
    background-color: ${props => props.color ? props.color : props.theme.colors.background};
    padding: 2px 5px;
    border-radius: 5px;
    border: 1px solid ${props => props.theme.colors.grey1};
    box-decoration-break: clone;
    color: ${props => props.color ? props.color : props.theme.colors.secondary};
    margin-right: ${props => props.spacing ? props.spacing : props.theme.spacings.xxxSmall};
  }
`

const FirmsBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
`

const ImageDiv = styled.div`
  padding: ${props => props.spacing ? props.spacing : props.theme.spacings.xSmall};
  width: 100px;

  @media ${props => props.theme.breakpoints.desktop} {
    padding: ${props => props.spacing ? props.spacing : props.theme.spacings.small};
    width: 150px;
  }
`

const HpImageDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  column-gap: ${props => props.spacing ? props.spacing : props.theme.spacings.small};
`

const HpImageDiv1 = styled.div`
  width: 100%;
  overflow: auto;
  position: relative;
  // transform: rotate(3deg);
  border-radius: ${props => props.theme.spacings.small};
`

const HpImageDiv2 = styled.div`
  display: none;
  width: 100%;
  overflow: auto;
  position: relative;
  // transform: rotate(-2deg);
  border-radius: ${props => props.theme.spacings.small};

  @media ${props => props.theme.breakpoints.desktop} {
    display: block;
  }
`

const IndexPage = ({data}) => {
  const GeneralInfo = data.allSanityGeneralInfo.nodes[0]
  const HpImages = data.allSanityHpImages.nodes[0]
  const MiscText = data.allSanityMiscText.nodes[0]
  const Modules = data.allSanityModule.nodes
  const Rencontres = data.allSanityRencontresSchoolyear.nodes
  const Alternance = data.allSanityAlternance.nodes[0]

  const HpImage1 = getImage(HpImages.image1.asset)
  const HpImage2 = getImage(HpImages.image2.asset)
  
  return (
    <Layout>
      <Seo/>
        <Sections.FullWidthSection>
        <Title>Master 2<br/>{GeneralInfo.degreename}</Title>
        <Subtitle>à l'<ColoredSpan>Université Paris 1 Panthéon-Sorbonne</ColoredSpan></Subtitle>
        <HpImageDiv>
          <HpImageDiv1><GatsbyImage image={HpImage1} alt={HpImages.image1.alternative_text}/></HpImageDiv1>
          <HpImageDiv2><GatsbyImage image={HpImage2} alt={HpImages.image2.alternative_text}/></HpImageDiv2>
        </HpImageDiv>
        </Sections.FullWidthSection>
        <Sections.FullWidthSection>
          <PortableText
            blocks={MiscText._rawIntrotext}
            serializers = {Serializer.bigtext}
          />
        </Sections.FullWidthSection>
        <Sections.FullWidthSection>
        <H2 id="maquette">Maquette</H2>
        <MaquetteGrid>
          {Modules.map((module, i) => (
            <FlexItem key={i}>
            <ModuleButton><Link to={module.slug.current} state={{modal: true}}>{module.title}</Link></ModuleButton>
            </FlexItem>
          ))}
        </MaquetteGrid>
        </Sections.FullWidthSection>
        <Sections.FullWidthSection>
        <H2 id="rencontres">Rencontres</H2>
        <PortableText
            blocks={MiscText._rawRencontresIntro}
            serializers = {Serializer.bigtext}
          />
        {Rencontres.slice(0, 2).map((rencontre, i) => (
          <div key={i}>
            <Schoolyear><span>{rencontre.schoolyear}</span></Schoolyear>

            {rencontre.visitor.map((visitor, j) => (
              <VisitorWrapper key={j}>
                <NameContainer context="nonblock"><span>{visitor.firstname} {visitor.surname}</span></NameContainer>
                <CompanyContainer context="nonblock"><span>{visitor.company}</span></CompanyContainer>
                <RoleContainer context="nonblock"><span>{visitor.role}</span></RoleContainer>
                {visitor.linksArray.map((visitorLink, k) => (
                  <NameUrl key={k} context="smallinfo"><a href={visitorLink.extUrl} target="_blank" rel='noopener noreferrer'>{visitorLink.extAccount}</a></NameUrl>
                ))}
              </VisitorWrapper>
            ))}
          </div>
        ))}
        </Sections.FullWidthSection>
        <Sections.FullWidthSection>
        <H2 id="alternance">Alternance</H2>
        <PortableText
            blocks={Alternance._rawGeneralIntro}
            serializers = {Serializer.bigtext}
          />
        <FirmsBox>
          {Alternance.alternance_firms_array.map((firm, i) => {
            const image = getImage(firm.logo.asset)
            return (
              <ImageDiv key={i}><a href={firm.extUrl} target="_blank" rel='noopener noreferrer'><GatsbyImage image={image} alt={firm.firm_name}/></a></ImageDiv>
            )
          }   
          )}
        </FirmsBox>
        <Button><Link to="/alternance" state={{modal: true}}>Plus d'infos sur l'alternance</Link></Button>
        </Sections.FullWidthSection>
        <Sections.CenteredSection>
        <H2 id="admissions">Admissions</H2>
        <PortableText
          blocks={data.allSanityAdmissions.nodes[0]._rawMainText}
          serializers = {Serializer.block}
        />
        </Sections.CenteredSection>
        {data.allSanityMiscText.nodes[0]._rawApprentissageTax ? (
        <Sections.CenteredSection>
        <H2 id="taxe_apprentissage">Taxe d'apprentissage</H2>
        <PortableText
          blocks={data.allSanityMiscText.nodes[0]._rawApprentissageTax}
          serializers = {Serializer.block}
        />
        </Sections.CenteredSection>
        ) : (<></>)}
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query HomepageQuery {
    allSanityMiscText {
      nodes {
        _rawIntrotext
        _rawRencontresIntro
        _rawApprentissageTax
      }
    },
    allSanityModule(sort: {fields: order, order: ASC}) {
      nodes {
        id
        order
        title
        slug {
          current
        }
      }
    },
    allSanityRencontresSchoolyear(sort: {fields: schoolyear, order: DESC}) {
      nodes {
        schoolyear
        visitor {
          firstname
          surname
          company
          role
          linksArray {
            extAccount
            extUrl
          }
        }
      }
    },
    allSanityAlternance {
      nodes {
        _rawGeneralIntro
        alternance_firms_array {
          extUrl
          firm_name
          logo {
            asset {
              gatsbyImageData(
                width: 200
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    },
    allSanityAdmissions {
      nodes {
        _rawMainText
      }
    },
    allSanityHpImages {
      nodes {
        image1 {
          asset {
            gatsbyImageData(
              width: 800
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
          alternative_text
        },
        image2 {
          asset {
            gatsbyImageData(
              width: 800
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
          alternative_text
        }
      }
    },
    allSanityGeneralInfo {
      nodes {
        degreename
      }
    }
  }`